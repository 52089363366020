<template>
  <div class="password-input">
    <ion-input
      v-bind="$props"
      ref="input"
      class="c-input"
      :value="modelValue"
      :type="type"
      @ionInput="update"
    />
    <ion-button color="light" class="toggle" @click="toggleType">
      <i v-if="isPassword" class="ti-eye" />
      <div v-else>-</div>
    </ion-button>
  </div>
</template>

<script lang="ts">
import { Emit, Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  name: 'IonCustomButton',
})
export default class IonCustomButton extends Vue {
  @Prop({ type: String }) public modelValue!: string;
  @Prop({ type: String }) public color!: string;
  @Prop({ type: String }) public placeholder!: string;
  @Prop({ type: Boolean }) public required!: boolean;
  @Prop({ type: Boolean }) public disabled!: boolean;

  public type = 'password';

  public get isPassword() {
    return this.type === 'password';
  }

  public update(event: CustomEvent<InputEvent>) {
    const target = event?.target as HTMLInputElement
    if (!target) return
  
    const { value } = target;
    this.$emit('update:modelValue', value)
  }

  public toggleType() {
    this.type = this.isPassword ? 'text' : 'password';
    try {
      const input = this.$refs.input as any;
      const inputElWrapper = input.$el as any;
      const inputEl = inputElWrapper.children[0] as any;
      inputEl.focus();
    } catch (error) {}
  }
}
</script>

<style scoped lang="sass">
.password-input
  position: relative
  display: flex
  align-items: center
  justify-content: flex-end
  .toggle
    position: absolute
    z-index: 2
    width: 20px
    height: 20px
    border-radius: 20px
    --border-radius: 20px
    --padding-start: 0 !important
    --padding-end: 0 !important
    --padding-top: 0 !important
    --padding-bottom: 0 !important
    --box-shadow: none
    right: 0.65rem
    background-color: transparent
    *
      fill: #214163
</style>
